export const HOST_URL = "https://localhost:8000"

export const INVALID_CRED = "Invalid credential !"

export const SECRET_KEY=  "shukrana_mushkurana"

export const API_KEY = "AIzaSyBgkTZ8lb9ZKj_DI7z7T9euwULgr5fDf1Q"

export const GOOGLE_MAP_API_URL = "https://maps.googleapis.com/maps/api/geocode/json";

export const GOOGLE_PLACE_API_URL = "https://maps.googleapis.com/maps/api/place/textsearch/json";

export const GOOGLE_CLIENT_ID = "521543791680-26jrl70j0duivecoh80f4jpun63cjbpn.apps.googleusercontent.com";

export const FACEBOOK_APP_ID = "2809614726027672";

export const EDAMAM_APP_ID = "2bbcafaf";

export const EDAMAM_APP_KEY = "f779cbf3ef41e8fc15a42df1cff35d0d";

// export const RESTAURANT_ADMIN_URL = `${window.location.host === "192.168.1.187:3001"?"192.168.1.187:3000":"http://103.232.124.169:11015"}`;
// export const RESTAURANT_ADMIN_URL = `${
    //   window.location.host === "192.168.1.187:3000"
    //     ? "http://192.168.1.187:3001"
    //     : "http://103.232.124.169:11029"
//         // window.location.host === "localhost:3001"
//         // ? "http://localhost:3000"
//         // : "http://103.232.124.169:11015"
//         window.location.host === "https://restaurant.pickypigs.com/"
//         ? "https://pickypigs.com/"
//         : "http://103.232.124.169:11015"
//     }`;
// export const RESTAURANT_ADMIN_URL = `${window.location.host === "localhost:3001"?"https://pickypigs.charlieandco.co.nz/restaurant_login":"https://pickypigs.charlieandco.co.nz/restaurant_login"}`;
// export const RESTAURANT_ADMIN_URL = `${window.location.host === "localhost:3001"?"http://139.59.40.108:11026/restaurant_login":"http://139.59.40.108:11026/restaurant_login"}`;
export const RESTAURANT_ADMIN_URL = `${window.location.host === "localhost:3001"?"https://www.pickypigs.com/restaurant_login":"https://www.pickypigs.com/restaurant_login"}`;

// export const SERVER_URL = "https://api.pickypigs.com:5003";
// export const SERVER_URL = "http://139.59.40.108:11025";
export const SERVER_URL = "https://api.pickypigs.com";

export const DEFAULT_LATITIDE = 25.1943424;

export const DEFAULT_LONGITUDE = 72.794112;

// export const ADMIN_URL="https://restaurant.pickypigs.com";
// export const ADMIN_URL="http://139.59.40.108:11027";
export const ADMIN_URL="https://restaurant.pickypigs.com";
