import axios from "axios";

if (window.location.host === "localhost:3000") {
  // axios.defaults.baseURL = "https://pickypigsapi.herokuapp.com";
  // axios.defaults.baseURL = "https://apps.narola.online:5003"; //live url
  // axios.defaults.baseURL = "http://192.168.100.39:5003"; // local
  // axios.defaults.baseURL = "http://192.168.1.213:5003"; // local suchit
  // axios.defaults.baseURL = "http://103.232.124.169:11014"; // * L
  // axios.defaults.baseURL = "http://192.168.1.102:5005"; // local ajay
  // axios.defaults.baseURL = "http://139.59.40.108:11025"; // Live API
  axios.defaults.baseURL = "https://api.pickypigs.com"; // Live API
  // axios.defaults.baseURL = "https://api.pickypigs.com:5003"; //live url
} else {
  // axios.defaults.baseURL = "https://pickypigsapi.herokuapp.com";
  // axios.defaults.baseURL = "https://api.pickypigs.com:5003"; //live url
  // axios.defaults.baseURL = "http://192.168.1.213:5003";
  // axios.defaults.baseURL = "http://103.232.124.169:11014"; // * L
  // axios.defaults.baseURL = "http://192.168.1.102:5005"; // local ajay
  // axios.defaults.baseURL = "http://139.59.40.108:11025"; // Live API
  axios.defaults.baseURL = "https://api.pickypigs.com"; // Live API
  // axios.defaults.baseURL = "https://apps.narola.online:5003";
  // axios.defaults.baseURL = "https://api.pickypigs.com:5003"; //live url 
}

const token = localStorage.getItem("access_token");
if (token) axios.defaults.headers.common = { "x-access-token": token };

export default axios;
